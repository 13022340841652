@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Black Ops One', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    

  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #F5F5F5;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background:#CDCDCF;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

@font-face {
  font-family: 'Black Ops One';
  src: url('./Assets/Fonts/BlackOpsOne-Regular.ttf') format('truetype');
}
.custom-tooltip {
  position: absolute;
  background-color: #e8def8;
  border: 1px solid #ddd;
  padding: 8px;
  color: #334155;
  font-size: 18px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 4px;
}

/* Custom styles for resizing */
.resize {
  resize: both;
  overflow: auto;
  max-width: 400px;
  max-height: 400px;
  min-width: 100px;
  min-height: 100px;
}
.bg-global{
  background: #212121;
  backdrop-filter: blur(5px);
}
.resize-handle::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.5);
  cursor: se-resize;
}
.bg-img{
  background-image: url('../src/Assets/Images/dottedbg.png');

}
.bg-comingsoon {
  background-image: url('https://img.freepik.com/free-vector/coming-soon-display-background-with-focus-light_1017-33741.jpg');
  background-size: cover; /* Ensures it covers the area without repeating */
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
}
/* Apply the font to the element */
h6 {
  font-family: 'Black Ops One', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


/* Change background color on click */
input[type="number"]:active,
input[type="number"]:focus {

  outline: none; /* Remove focus outline */
  border-color: #e0d4f1; /* Keep border transparent on focus */
}

/* Disabled state */
input[type="number"]:disabled {
  background-color: #cac4d0; /* Greyed-out color for disabled inputs */
}
/* Hide the default arrow */
.custom-select-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f0a58e;
  padding-right: 2.5rem; /* Add space for the custom arrow */
  position: relative; /* Create a positioning context for the pseudo-element */
}

/* Custom arrow using a pseudo-element */
.custom-select-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem; /* Position the arrow */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid black;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the arrow from blocking select interactions */
}
.toast-position {
  top: 20rem !important;
}

@keyframes rotating-border {
  0% {
    border-image: linear-gradient(0deg, #65558F, #65558F) 1;
  }
  100% {
    border-image: linear-gradient(360deg, #65558F, #65558F) 1;
  }
}

.highlight-border {
  border: 2px solid transparent;
  border-image-slice: 1;
  animation: rotating-border 5s linear infinite;
}
